// Add your custom JS here.

( function( $ ) {

	// Site title and description.
	$('body').scrollspy({ 
		target: '#navbar-top',
		method: 'offset',
		offset: 56
	});

	// Smooth Scrolling
	// spy and scroll menu boogey
	$("#navbar-top a[href^='#']").on('click', function(e) {
		if (this.hash !== '') {
		   // prevent default anchor click behavior
		   e.preventDefault()

		   // store hash
		   var hash = this.hash

		   // animate
		   $('html, body').animate({
			   scrollTop: $(this.hash).offset().top + 0
			 }, 500, function(){
			   window.location.hash = hash;
			 })
		}
	});


	// Float label
	var inputGroups = '.form-group';
	var inputSelectors = '.form-control';

	$(document).on('focus.form.group change.form.group', inputSelectors, function() {
		var self = $(this);
		var label = self.closest(inputGroups);

		label.addClass('is-focused');
	});

	$(document).on('blur.form.group', inputSelectors, function() {
		var self = $(this);
		var label = self.closest(inputGroups);
		var value = self.val();

		// When there is a value, we should inject is-filled.
		if ($.trim(value) !== '') {
			label.addClass('is-filled');
			label.removeClass('is-focused');
			return;
		}

		label.removeClass('is-filled');
		label.removeClass('is-focused');
	});
		
	$(document).ready(function() {
		$(inputSelectors).trigger('blur.form.group');
	});


	//caches a jQuery object containing the header element
	var header = $(".nav-shadow-off");
	$(window).scroll(function() {
		var scroll = $(window).scrollTop();

		if (scroll >= 300) {
			header.removeClass('nav-shadow-off').addClass("nav-shadow-on");
		} else {
			header.removeClass("nav-shadow-on").addClass('nav-shadow-off');
		}
	});

	// SLICK
	$('.slick-for').slick({
		arrows: false,
		slidesToShow: 2,
		slidesToScroll: 2,
		infinite: false,
		dots: true,
		// dotsClass: 'custom-dots', //slick generates this <ul.custom-dots> within the appendDots container
		appendDots:'.slick-dots-container',
		// appendDots:$(".dots-container"),

		responsive: [
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	// var slider = $('.slick-for');
	// $('.slick-custom-nav .slick-custom-nav__prev').on('click', function() {
	// 	$(slider).slick('slickPrev');
	// });
	// $('.slick-custom-nav .slick-custom-nav__next').on('click', function() {
	// 	$(slider).slick('slickNext');
	// });

	

	$('.slick-js-multi-row').slick({
		arrows: false,
		dots: true,
		slidesPerRow: 3,
		rows: 3,
		infinite: false,
		responsive: [
			{
				breakpoint: 900,
				settings: {
					slidesPerRow: 1,
					rows: 6,
					// slidesToShow: 1,
					// slidesToScroll: 1
				}
			}
		]
	});

	$('.slick-js-single-row').slick({
		arrows: false,
		dots: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		infinite: false,
		responsive: [
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.slick-js-client-logo').slick({
		arrows: true,
		// dots: true,
		slidesToShow: 6,
		slidesToScroll: 6,
		infinite: false,
		// variableWidth: true,

		responsive: [
			{
				breakpoint: 800,

				settings: {
					// variableWidth: true,
					slidesToShow: 3,
					slidesToScroll: 3
				}
			}
		]
	});

	$('.slick-js-single-row-service').slick({
		arrows: false,
		dots: true,
		// slidesToShow: 3,
		// slidesToScroll: 3,
		infinite: false,
		slidesPerRow: 3,
		rows: 2,
		responsive: [
			{
				breakpoint: 800,
				settings: {
					// slidesToShow: 1,
					// slidesToScroll: 3
					slidesPerRow: 1,
					rows: 3,
				}
			}
		]
	});

	$('.hero-slick').slick({
		arrows: false,
		dots: true,
		// autoplay: true
	});


	$('#toggle').click(function() {
	   $(this).toggleClass('encendido');
	   $('#capa-sobre').toggleClass('abrir');
	  });

	$('.navbar').on('shown.bs.collapse', function () {
	  // alert('animation complete');
	  $('body').addClass('modal-open');
	});
	$('.navbar').on('hidden.bs.collapse', function () {
	  // alert('animation complete');
	  $('body').removeClass('modal-open');
	})


	

	// Example starter JavaScript for disabling form submissions if there are invalid fields
	  window.addEventListener('load', function() {
		// Fetch all the forms we want to apply custom Bootstrap validation styles to
		var forms = document.getElementsByClassName('needs-validation');
		// Loop over them and prevent submission
		var validation = Array.prototype.filter.call(forms, function(form) {
		  form.addEventListener('submit', function(event) {
			if (form.checkValidity() === false) {
			  event.preventDefault();
			  event.stopPropagation();
			}
			form.classList.add('was-validated');
		  }, false);
		});
	  }, false);

	

} )( jQuery );